import GoogleMapReact from "google-map-react"
import React, { useState } from "react"

import { bianca } from "./google-map-theme"
import MapMarker from "./MapMarker"

export default function RestaurantsMap({ restaurants, center, zoom }) {
  const [disableDoubleClickZoom, setDisableDoubleClickZoom] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState(center)
  const isSinglePin = restaurants.length === 1
  const mapOptions = {
    styles: bianca,
    clickableIcons: false,
    disableDoubleClickZoom,
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyAi12GbFNTSwJ8b5IUrn2XBceu45kkVr10" }}
      center={selectedMarker}
      defaultZoom={zoom}
      options={mapOptions}
    >
      {restaurants.map((restaurant) => (
        <MapMarker
          key={restaurant.heading}
          onClick={() =>
            setSelectedMarker({ lat: restaurant.lat, lng: restaurant.lng })
          }
          onMouseEnter={() => setDisableDoubleClickZoom(true)}
          onMouseLeave={() => setDisableDoubleClickZoom(false)}
          popupOpened={isSinglePin}
          {...restaurant}
        />
      ))}
    </GoogleMapReact>
  )
}
