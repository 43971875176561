import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import RestaurantsMap from "../components/restaurants/RestaurantsMap"
import SEO from "../components/seo"
import {
  selectProductGroups,
  selectRestaurants,
  selectRestaurantsPageContent,
  selectTranslations,
} from "../graphql/selectors"
import s from "./restaurants.module.scss"

export default function RestaurantsPage(props) {
  const { data, pageContext } = props
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const restaurants = selectRestaurants(data)
  const { seo, map } = selectRestaurantsPageContent(data)

  return (
    <Layout
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <div className={s.map}>
        <RestaurantsMap restaurants={restaurants} {...map} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query RestaurantsPage($locale: String) {
    ...LayoutFragment
    ...RestaurantsFragment
    ...RestaurantsPageFragment
  }
`
