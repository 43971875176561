import React, { useRef, useState } from "react"
import { useClickAway } from "react-use"

import { BurgerKingMarkerIcon } from "../common/Icons"
import s from "./MapMarker.module.scss"

export default function MapMarker({
  addressLines,
  heading,
  onClick,
  onMouseEnter,
  onMouseLeave,
  phoneNumber,
  popupOpened = false,
  workingHours,
}) {
  const [popupVisible, setPopupVisible] = useState(popupOpened)
  const offClickContainerRef = useRef()

  useClickAway(offClickContainerRef, () => setPopupVisible(false))

  const _makePopupVisible = () => {
    onClick()
    setPopupVisible(!popupVisible)
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={offClickContainerRef}
    >
      <BurgerKingMarkerIcon
        className={s.mapMarker}
        onClick={() => _makePopupVisible()}
      />
      {popupVisible ? (
        <div className={s.popup}>
          <h3 dangerouslySetInnerHTML={{ __html: heading }} />
          {addressLines.map((addressLine) => (
            <p key={addressLine}>{addressLine}</p>
          ))}
          <p>{phoneNumber}</p>
          <p dangerouslySetInnerHTML={{ __html: workingHours }} />
        </div>
      ) : null}
    </div>
  )
}
